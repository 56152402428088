import { debounce, get, includes, isEmpty } from 'lodash'
import config from '../../config'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import { aiFeedErrorDownloadFailed, aiFeedSearch } from '../../reduxSetup/commonActions/TelemetryActions'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { downloadFile, getVendorIdFromState } from '../../utils/common'
import CustomError from '../../utils/CustomError'
import { ROUTE_MODULES } from '../../config/genericConstants'
import {
  DOWNLOAD_ERROR_FILE_FAILURE,
  DOWNLOAD_ERROR_FILE_REQUEST,
  DOWNLOAD_ERROR_FILE_SUCCESS,
  GET_TOTAL_FEED_COUNT_FAILURE,
  GET_TOTAL_FEED_COUNT_REQUEST,
  GET_TOTAL_FEED_COUNT_SUCCESS,
  INITIATE_ERROR_FILE_DOWNLOAD_FAILURE,
  INITIATE_ERROR_FILE_DOWNLOAD_REQUEST,
  INITIATE_ERROR_FILE_DOWNLOAD_SUCCESS,
  REMOVE_ERROR_DOWNLOAD_FILE,
  SEARCH_FEED_ITEM_REQUEST,
  SET_FEED_ROW_COUNT
} from './FeedStatus.types'

export const setFeedRowCount = ({ count }) => dispatch => {
  return dispatch({
    type: SET_FEED_ROW_COUNT,
    payload: { count }
  })
}

/**
 *
 * @param {Number} lastRowCount
 * @param {Number} pageNo
 * @param {Number} pageSize
 * @returns {function(...[*]=)}
 */
export const calculateTotalFeedRows = (lastRowCount, pageNo = 0) => (dispatch, getState) => {
  const ROUTE_NAME = getState().router.location.pathname
  //send vendorId as 0000 in api for requests made by admin under admin feeds page
  const VENDOR_ID = (ROUTE_NAME !== ROUTE_MODULES?.toolsreporting?.adminuploadrequest?.path) ? getVendorIdFromState(getState) : '0000'
  const SEARCH_QUERY = getState().SSHPaginatedTableReducer.searchQuery

  const FILTER_URL_PATH = SEARCH_QUERY !== null ? '/filter' : ''
  const FILTER_PARAM = SEARCH_QUERY !== null ? `searchValue=${SEARCH_QUERY}&` : ''

  return dispatch({
    [CALL_API]: {
      types: [GET_TOTAL_FEED_COUNT_REQUEST, GET_TOTAL_FEED_COUNT_SUCCESS, GET_TOTAL_FEED_COUNT_FAILURE],
      endpoint: `${config.get('feedStatus')}${VENDOR_ID}/feeds${FILTER_URL_PATH}?${FILTER_PARAM}pageNo=${pageNo +
        1}&pageSize=100&vendorId=${VENDOR_ID}`,
      method: 'GET'
    }
  })
    .then(({ type, response }) => {
      if (type === GET_TOTAL_FEED_COUNT_SUCCESS) {
        const rowCount = response?.feedLists?.length
        if (rowCount === 100) {
          dispatch(setFeedRowCount({ count: lastRowCount + rowCount }))
          return dispatch(calculateTotalFeedRows(lastRowCount + 100, pageNo + 1))
        }
        return dispatch(setFeedRowCount({ count: lastRowCount + rowCount }))
      }
      return Promise.resolve()
    })
    .catch(() => dispatch(setFeedRowCount({ count: 100 })))
}

export const fetchFeedItems = () => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const ROUTE_NAME = getState().router.location.pathname
  //send vendorId as 0000 in api for requests made by admin under admin feeds page
  const VENDOR_ID = (ROUTE_NAME !== ROUTE_MODULES?.toolsreporting?.adminuploadrequest?.path) ? getVendorIdFromState(getState) : '0000'
  const TOTAL_ROWS = getState().FeedNew.totalRows
  const SEARCH_QUERY = getState().SSHPaginatedTableReducer.searchQuery

  const FILTER_URL_PATH = SEARCH_QUERY !== null ? '/filter' : ''
  const FILTER_PARAM = SEARCH_QUERY !== null ? `searchValue=${SEARCH_QUERY}&` : ''

  if (!VENDOR_ID && ROUTE_NAME !== ROUTE_MODULES?.toolsreporting?.adminuploadrequest?.path) {
    return Promise.resolve()
  }

  if (SEARCH_QUERY) {
    dispatch(aiFeedSearch())
  }

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${config.get('feedStatus')}${VENDOR_ID}/feeds${FILTER_URL_PATH}?${FILTER_PARAM}pageNo=${PAGE_NO +
        1}&pageSize=${PAGE_SIZE}&vendorId=${VENDOR_ID}`,
      method: 'GET',
      payload: { vendorId: VENDOR_ID, PAGE_SIZE, PAGE_NO, pathName: ROUTE_NAME }
    }
  })
    .then(({ type, response }) => {
      if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS && (TOTAL_ROWS === null || !!SEARCH_QUERY)) {
        dispatch(calculateTotalFeedRows(0, 0))
        return dispatch(setFeedRowCount({ count: response.feedLists.length }))
      }
      return Promise.resolve()
    })
    .catch(() => { })
}

export const checkFeedDownloadStatus = () => (dispatch, getState) => {
  getState().FeedNew.downloads.forEach(feed => {
    if (includes(['INITIATED', 'IN_PROGRESS'], feed.downloadFileExist)) {
      return dispatch(
        downloadErrorFile({
          ackId: feed.ackId,
          shipNodeId: feed.shipNodeId,
          feedType: feed.feedType
        })
      )
    }

    if (feed.downloadFileExist === 'COMPLETED' && feed.downloaded !== true) {
      return dispatch(
        downloadFeedErrorFile({
          ackId: feed.ackId,
          errorFileName: feed.errorFileName
        })
      )
    }

    return false
  })
}

let debouncedFeedDownloadStatus = null
/**
 *
 * @param {String} ackId
 * @param {String} shipNodeId
 * @param {String} feedType
 * @param {Boolean} retry
 * @returns {function(*, *=): Q.Promise<any> | undefined | Promise<void>}
 */
export const downloadErrorFile = ({ ackId, shipNodeId, feedType, retry }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  debouncedFeedDownloadStatus =
    debouncedFeedDownloadStatus === null
      ? debounce(() => dispatch(checkFeedDownloadStatus()), 5000)
      : debouncedFeedDownloadStatus

  return dispatch({
    [CALL_API]: {
      types: [
        INITIATE_ERROR_FILE_DOWNLOAD_REQUEST,
        INITIATE_ERROR_FILE_DOWNLOAD_SUCCESS,
        INITIATE_ERROR_FILE_DOWNLOAD_FAILURE
      ],
      endpoint: `${config.get(
        'feedDownload'
      )}/${VENDOR_ID}/shipnode/${shipNodeId}/feed/${ackId}/error/lines?type=${feedType}${retry === true ? '&reInitiateErrorFileDownload=true' : ''
        }&vendorId=${VENDOR_ID}`,
      method: 'GET',
      payload: { ackId, feedType, shipNodeId, retry }
    }
  })
    .then(response => {
      if (get(response, 'response.downloadFileExist', null) === 'FAILED') {
        throw new CustomError('Failed to download error file', {})
      }

      if (response.type === INITIATE_ERROR_FILE_DOWNLOAD_SUCCESS) {
        return debouncedFeedDownloadStatus()
      }

      return false
    })
    .catch(() => {
      dispatch(aiFeedErrorDownloadFailed({ ackId, feedType }))
      return dispatch({
        type: INITIATE_ERROR_FILE_DOWNLOAD_FAILURE,
        payload: { ackId, feedType, shipNodeId }
      })
    })
}

/**
 *
 * @param {String} ackId
 * @param {String} errorFileName
 * @returns {function(...[*]=)}
 */
export const downloadFeedErrorFile = ({ ackId, errorFileName }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)

  return dispatch({
    [CALL_API]: {
      types: [DOWNLOAD_ERROR_FILE_REQUEST, DOWNLOAD_ERROR_FILE_SUCCESS, DOWNLOAD_ERROR_FILE_FAILURE],
      endpoint: `${config.get('feedDownload')}/feed/${errorFileName}/error/lines?vendorId=${VENDOR_ID}`,
      method: 'GET',
      isExcel: true,
      payload: { ackId }
    }
  }).then(response => {
    if (response.type === DOWNLOAD_ERROR_FILE_SUCCESS) {
      downloadFile({ data: response.response }, errorFileName)
      setTimeout(() => dispatch(markErrorFileDownloaded({ ackId })), 5000)
    }
  })
}

export const markErrorFileDownloaded = ({ ackId }) => dispatch => {
  return dispatch({
    type: REMOVE_ERROR_DOWNLOAD_FILE,
    payload: { ackId }
  })
}

/**
 *
 * @param {String} searchQuery
 * @returns {function(...[*]=)}
 */
export const searchFeed = ({ searchQuery }) => dispatch => {
  if (isEmpty(searchQuery)) {
    return false
  }

  return Promise.resolve(
    dispatch({
      type: SEARCH_FEED_ITEM_REQUEST,
      payload: { searchQuery }
    })
  ).then(dispatch(fetchFeedItems()))
}
